import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import CircularProgress from '@mui/material/CircularProgress';

export type ActionCellProps = {
  onEditClick?: () => void;
  onDeleteConfirm: () => void;
  deleteDisabled: boolean;
  isDeleting: boolean;
  name: string;
  noEdit?: boolean;
};

export default function ActionCell(props: ActionCellProps) {
  const {
    onEditClick,
    onDeleteConfirm,
    deleteDisabled,
    isDeleting,
    noEdit,
    name,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  if (isDeleting) return <CircularProgress size={24} />;
  return (
    <>
      {!noEdit && (
        <MDButton
          iconOnly
          size="medium"
          color="info"
          variant="text"
          disabled={isDeleting}
          onClick={onEditClick}
        >
          <span className="material-icons-round">edit</span>
        </MDButton>
      )}
      <MDButton
        iconOnly
        disabled={deleteDisabled || isDeleting}
        size="medium"
        color="error"
        variant="text"
        onClick={openDialog}
      >
        <span className="material-icons-round">delete</span>
      </MDButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete ${name}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={closeDialog} color="info">
            Cancel
          </MDButton>
          <MDButton
            onClick={() => {
              onDeleteConfirm();
              closeDialog();
            }}
            color="error"
            autoFocus
          >
            Delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
