/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Licence Page: https://www.creative-tim.com/Licence/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useCallback, useMemo } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import Footer from 'examples/Footer';

// NewLicence page components
import LicenceInfo from './LicenceInfo';
import LicenceConfirm from './LicenceConfirm';
import { useAuth, useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import {
  GenericResponse,
  LicenceGenerationRequest,
  PortalUser,
  ServiceProvider,
  SubscriptionPlan,
} from 'firebaseModels';
import { usePortalUser, useServiceProvider } from 'routes';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import {
  collection,
  doc,
  updateDoc,
  orderBy,
  query,
  where,
  documentId,
} from 'firebase/firestore';
import Autocomplete from '@mui/material/Autocomplete';
import MDInput from 'components/MDInput';

function getSteps(): string[] {
  return ['1. Licence Info', '2. Confirm'];
}

function getStepContent(
  stepIndex: number,
  licenceGenerationData: LicenceGenerationData,
  setLicenceGenerationData: Function
): JSX.Element {
  switch (stepIndex) {
    case 0:
      return (
        <LicenceInfo
          licenceGenerationData={licenceGenerationData}
          setLicenceGenerationData={setLicenceGenerationData}
        />
      );
    case 1:
      return <LicenceConfirm licenceGenerationData={licenceGenerationData} />;
    default:
      return null;
  }
}

export type LicenceGenerationData = {
  plan: SubscriptionPlan;
  description: string | null;
  numberOfLicences: number;
  questionnaires: boolean;
  questionnairesOnByDefault: boolean;
};

type LicenceGenerationStatus = {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
};

function NewLicence(): JSX.Element {
  const [licenceGenerationData, setLicenceGenerationData] =
    useState<LicenceGenerationData>({
      plan: null,
      description: null,
      numberOfLicences: 1,
      questionnaires: true,
      questionnairesOnByDefault: false,
    });

  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepDataIsValid, setStepDataIsValid] = useState<boolean>(false);
  const [licenceGenerationStatus, setLicenceGenerationStatus] =
    useState<LicenceGenerationStatus>({
      status: 'idle',
      message: '',
    });
  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;

  const { currentUser } = useAuth();
  const email = currentUser?.providerData[0]?.email || '';
  const { portalUser } = usePortalUser(email);
  const isSuperAdmin =
    portalUser && (portalUser as PortalUser).level === 'superadmin';

  const isReseller =
    portalUser && (portalUser as PortalUser).level === 'reseller';

  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(
    portalUser?.provider.id || null
  );
  const firestore = useFirestore();

  const providerRef = useMemo(
    () =>
      selectedProviderId
        ? doc(firestore, 'ServiceProvider', selectedProviderId)
        : null,
    [selectedProviderId, firestore]
  );

  const { serviceProvider } = useServiceProvider(providerRef);
  const serviceProviderCollection = useMemo(
    () => collection(firestore, 'ServiceProvider'),
    [firestore]
  );

  const serviceProviderConstraints = useMemo(
    () =>
      !isSuperAdmin && !isReseller
        ? where(documentId(), '==', portalUser?.provider.id || 'none')
        : undefined,
    [isReseller, isSuperAdmin, portalUser?.provider.id]
  );

  const serviceProviderQuery = useMemo(
    () =>
      query(
        serviceProviderCollection,
        serviceProviderConstraints,
        orderBy('name', 'asc')
      ),
    [serviceProviderCollection, serviceProviderConstraints]
  );

  // console.log('serviceProviderQuery', serviceProviderQuery);
  // console.log('portalUser', portalUser);

  const { status: serviceProviderStatus, data: serviceProviders } =
    useFirestoreCollectionData(serviceProviderQuery, {
      idField: 'id', // this field will be added to the object created from each document
    });

  useEffect(() => {
    if (
      !selectedProviderId &&
      portalUser &&
      serviceProviders &&
      serviceProviders.length > 0
    ) {
      setSelectedProviderId(portalUser?.provider.id);
    }
  }, [portalUser, selectedProviderId, serviceProviders]);

  const functions = useFunctions();
  const generate_licences = useMemo(
    () =>
      httpsCallable<LicenceGenerationRequest, GenericResponse>(
        functions,
        'generatelicences'
      ),
    [functions]
  );

  useEffect(() => {
    if (
      licenceGenerationData.plan &&
      licenceGenerationData.numberOfLicences > 0 &&
      licenceGenerationData.numberOfLicences <= 100
    ) {
      setStepDataIsValid(true);
    } else {
      setStepDataIsValid(false);
    }
  }, [licenceGenerationData]);

  useEffect(() => {
    if (!licenceGenerationData.plan && serviceProvider?.subscriptionPlan) {
      setLicenceGenerationData({
        ...licenceGenerationData,
        plan: serviceProvider.subscriptionPlan,
      });
    }
  }, [licenceGenerationData, serviceProvider?.subscriptionPlan]);

  const handleNext = useCallback(
    () => setActiveStep(activeStep + 1),
    [activeStep]
  );

  const handleBack = useCallback(
    () => setActiveStep(activeStep - 1),
    [activeStep]
  );

  const generateLicences = useCallback(() => {
    const {
      plan,
      description,
      numberOfLicences,
      questionnaires,
      questionnairesOnByDefault,
    } = licenceGenerationData;
    setLicenceGenerationStatus({ status: 'loading', message: '' });
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    generate_licences({
      planId: plan.id,
      quantity: numberOfLicences,
      providerId: serviceProvider.id,
      description,
      questionnaires,
      questionnairesOnByDefault,
      timezone,
    })
      .then((result) => {
        const response = result.data;

        setLicenceGenerationStatus({
          status: response.success ? 'success' : 'error',
          message: response.message,
        });
      })
      .catch((error) => {
        setLicenceGenerationStatus({ status: 'error', message: error.message });
      });
  }, [generate_licences, licenceGenerationData, serviceProvider?.id]);

  const selectedProvider = useMemo(
    () =>
      (serviceProviders?.find(
        (provider) => provider.id === selectedProviderId
      ) || null) as ServiceProvider | null,
    [selectedProviderId, serviceProviders]
  );

  const providerDropdownOptions = useMemo(
    () =>
      serviceProviders?.filter(
        (provider) =>
          isSuperAdmin ||
          provider.resellerId === portalUser?.provider.id ||
          provider.id === portalUser?.provider.id
      ) as ServiceProvider[],
    [isSuperAdmin, portalUser?.provider.id, serviceProviders]
  );

  const onChangeProvider = useCallback(
    (event: any, value: ServiceProvider | null) => {
      setSelectedProviderId(value?.id || null);
    },
    []
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={0} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Generate New Licences
                </MDTypography>
              </MDBox>
            </MDBox>

            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
                mt={2}
                mx={2}
              >
                {(isSuperAdmin || isReseller) &&
                  serviceProviders &&
                  serviceProviders.length > 0 && (
                    <Autocomplete
                      blurOnSelect
                      sx={{ minWidth: 350 }}
                      options={providerDropdownOptions}
                      getOptionKey={(option: ServiceProvider) => option.id}
                      getOptionLabel={(option: ServiceProvider) =>
                        option.name + (option.trialProvider ? ' (trial)' : '')
                      }
                      value={selectedProvider}
                      renderInput={(params) => {
                        return (
                          <MDInput
                            variant="outlined"
                            {...params}
                            label="Service Provider"
                            placeholder="Select Service Provider"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                backgroundColor: 'white',
                              },
                            }}
                            InputLabelProps={{
                              ...params.InputLabelProps,
                              style: {
                                marginTop: '.25em',
                              },
                            }}
                          />
                        );
                      }}
                      renderOption={(props, value) => (
                        <Typography
                          {...props}
                          variant="body1"
                          sx={{
                            color: 'black',
                            fontWeight: !value.trialProvider
                              ? 'bold'
                              : 'normal',
                          }}
                        >
                          {value.name}
                          {value.trialProvider && value.resellerName
                            ? ` - (${value.resellerName} trial)`
                            : ''}
                          {value.trialProvider && !value.resellerName
                            ? ` - (trial)`
                            : ''}
                        </Typography>
                      )}
                      onChange={onChangeProvider}
                    />
                  )}
              </MDBox>

              <MDBox p={2}>
                <MDBox>
                  {getStepContent(
                    activeStep,
                    licenceGenerationData,
                    setLicenceGenerationData
                  )}
                </MDBox>

                {licenceGenerationStatus.status === 'error' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="error">
                      {licenceGenerationStatus.message}
                    </MDTypography>
                  </MDBox>
                )}
                {licenceGenerationStatus.status === 'success' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="success">
                      Successfully generated licenses
                    </MDTypography>
                  </MDBox>
                )}
                {licenceGenerationStatus.status === 'loading' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="text">
                      Generating licenses...
                    </MDTypography>
                  </MDBox>
                )}
                <MDBox>
                  <MDBox
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        back
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="dark"
                      disabled={
                        !stepDataIsValid ||
                        licenceGenerationStatus.status === 'loading' ||
                        licenceGenerationStatus.status === 'success'
                      }
                      onClick={!isLastStep ? handleNext : generateLicences}
                    >
                      {isLastStep ? 'generate' : 'next'}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default NewLicence;
