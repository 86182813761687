import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFunctions } from 'reactfire';
import Card from '@mui/material/Card';
// import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import CustomerCell from './components/CustomerCell';
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import DefaultCell from './components/DefaultCell';
import { useNavigate } from 'react-router-dom';
import ActionCell from './components/ActionCell';
import {
  Alert,
  AlertTitle,
  CardContent,
  CardHeader,
  CircularProgress,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { PortalUser } from 'firebaseModels';
import MDTypography from 'components/MDTypography';

type FetchStatus = 'idle' | 'loading' | 'success' | 'error';

type UserDeleteRequest = {
  email: string;
};

type UserDeleteResponse = {
  success: boolean;
  message: string;
};
function UserList(): JSX.Element {
  const navigate = useNavigate();
  // const [menu, setMenu] = useState(null);
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [users, setUsers] = useState<Array<PortalUser>>([]);
  const [error, setError] = useState<Error | null>(null);

  const functions = useFunctions();

  const get_user_list = useCallback(() => {
    return httpsCallable<void, Array<PortalUser>>(functions, 'listusers');
  }, [functions]);

  const delete_user = useCallback(() => {
    return httpsCallable<UserDeleteRequest, UserDeleteResponse>(
      functions,
      'deleteportaluser'
    );
  }, [functions]);

  // const openMenu = (event: any) => setMenu(event.currentTarget);
  // const closeMenu = () => setMenu(null);

  useEffect(() => {
    if (isLoading === false && status === 'idle') {
      setIsLoading(true);
      setStatus('loading');
      setError(null);
      get_user_list()()
        .then((result) => {
          setUsers(result.data);
          setIsLoading(false);
          setStatus('success');
        })
        .catch((error: Error) => {
          setIsLoading(false);
          setStatus('error');
          setError(error);
          console.error('Error fetching user list', error);
        });
    }
  }, [get_user_list, isLoading, status]);

  const columns = useMemo<Array<Column>>(
    () => [
      {
        Header: 'user id',
        accessor: 'id',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'name',
        accessor: 'name',
        Cell: ({ value: [name, data] }: any) => (
          <CustomerCell
            image={data.image}
            color={data.color || 'dark'}
            name={name}
          />
        ),
      },
      {
        Header: 'contact email',
        accessor: 'email',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'user level',
        accessor: 'level',
        align: 'right',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: 'actions',
        accessor: (row: any) => ({ id: row.id, email: row.email }),
        align: 'right',
        disableSortBy: true,
        Cell: ({ value: { email, id } }) => (
          <ActionCell
            name={email}
            deleteDisabled={isDeleting}
            isDeleting={isDeleting}
            onEditClick={() => {
              navigate(`/users/${id}`);
            }}
            onDeleteConfirm={() => {
              setIsDeleting(true);
              delete_user()({ email: id })
                .then((result) => {
                  setIsDeleting(false);
                })
                .catch(() => {
                  setIsDeleting(false);
                })
                .finally(() => {
                  // trigger a refresh
                  setIsLoading(false);
                  setStatus('idle');
                });
            }}
          />
        ),
      },
    ],
    [delete_user, isDeleting, navigate]
  );

  const data = useMemo<Array<any>>(
    () =>
      users.map((user) => ({
        ...user,
        name: [user.name, { image: user.name.charAt(0) }],
      })),
    [users]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      initialState: { pageIndex: 0, sortBy: [{ id: 'name' }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // console.log('providers', providers);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <MDBox display="flex">
            {/* <MDButton
              variant={menu ? 'contained' : 'outlined'}
              color="dark"
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu} */}
            {/* <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        <Card>
          <CardHeader title="User List" />
          {error && (
            <Alert severity="error" variant="filled" sx={{ m: 2 }}>
              <AlertTitle>Error</AlertTitle>
              {'An error occurred while fetching the user list: '}
              {error.message}
            </Alert>
          )}
          <CardContent>
            {status === 'loading' && <CircularProgress />}
            <DataTable
              key="user-list"
              tableInstance={tableInstance}
              entriesPerPage={{
                defaultValue: 50,
                entries: [50, 100, 200, 500, 1000],
              }}
              canSearch
            />
          </CardContent>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserList;
