import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// react-router-dom components
import { Theme } from '@mui/material/styles';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from './FormField';

// Images
import bgImage from 'assets/images/taustakuva.jpg';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Footer from 'layouts/authentication/components/Footer';

import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import {
  GenericResponse,
  EmailConfirmRequest,
  SmsConfirmRequest,
} from 'firebaseModels';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

function FreelicenceEmailConfirm(): JSX.Element {
  const [smsConfirmationCode, setSmsConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [message, setMessage] = useState('');
  const [emailConfirmationSuccess, setEmailConfirmationSuccess] =
    useState(false);
  const { emailConfirmationCode } = useParams();

  const functions = useFunctions();
  const confirm_email = httpsCallable<EmailConfirmRequest, GenericResponse>(
    functions,
    'confirmemail'
  );
  const confirm_sms = httpsCallable<SmsConfirmRequest, GenericResponse>(
    functions,
    'confirmsms'
  );

  useEffect(() => {
    console.log('emailConfirmationCode', emailConfirmationCode);
    if (!emailConfirmationSuccess && !loading) {
      setLoading(true);
      confirm_email({
        emailConfirmationCode,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
        .then((result) => {
          console.log('result', result);
          setEmailConfirmationSuccess(result.data.success);
          if (result.data.message === 'Request already confirmed') {
            setSuccess(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          setError(error.message);
          setLoading(false);
          setEmailConfirmationSuccess(false);
        });
    }
  }, [confirm_email, emailConfirmationCode, emailConfirmationSuccess, loading]);

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      setError('');
      setMessage('');
      setLoading(true);
      setSuccess(false);
      confirm_sms({
        emailConfirmationCode,
        smsConfirmationCode,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
        .then((result) => {
          console.log('result', result);
          setSuccess(result.data.success);
          setMessage(result.data.message);
          setLoading(false);
        })
        .catch((error) => {
          console.log('error', error);
          setSuccess(false);
          setMessage(error.message);
          setLoading(false);
        });
    },
    [confirm_sms, emailConfirmationCode, smsConfirmationCode]
  );

  if (loading) {
    return (
      <PageLayout>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={10}
        >
          <CircularProgress />
        </MDBox>
      </PageLayout>
    );
  }

  if (!emailConfirmationSuccess) {
    return (
      <PageLayout>
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }: Theme) =>
              bgImage &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />

        <MDBox px={1} width="100%" height="100vh" mx="auto">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={11} xl={8}>
              <Card sx={{ maxWidth: 'lg', margin: 'auto' }}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Error confirming email
                  </MDTypography>
                </MDBox>
                <MDBox pt={2} pb={3} px={3}>
                  <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Error Message
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {message}
                      </Typography>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </PageLayout>
    );
  }

  const dataIsValid = smsConfirmationCode.length > 0;

  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }: Theme) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={12} xl={4}>
            <Card sx={{ maxWidth: 'sm', margin: 'auto' }}>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Email Confirmation Successful
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  color="white"
                  my={1}
                >
                  An SMS message has been sent to your phone number with a
                  verification code.
                </MDTypography>
              </MDBox>
              <MDBox pt={2} pb={3} px={3}>
                <Grid container spacing={0.5}>
                  {success ? (
                    <Grid item xs={12}>
                      <MDTypography variant="h6" color="success">
                        Licences Sent Successfully
                      </MDTypography>
                      <MDTypography color="success">
                        Check your email for the license codes and instructions
                      </MDTypography>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Enter the verification code sent to your phone number
                          via SMS
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          type="text"
                          label="Verification code"
                          name="smsConfirmationCode"
                          value={smsConfirmationCode}
                          success={smsConfirmationCode.length > 0}
                          onChange={(e: any) =>
                            setSmsConfirmationCode(e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {(error || message) && (
                          <>
                            <MDTypography
                              variant="h6"
                              color="error"
                              sx={{ mt: 3 }}
                            >
                              Error
                            </MDTypography>
                            <MDTypography variant="body1" color="error">
                              {error}
                              {message}
                            </MDTypography>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          disabled={(loading || !dataIsValid) && !success}
                          sx={{ width: '100%', mt: 3 }}
                          onClick={handleSubmit}
                        >
                          Confirm
                        </MDButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

export default FreelicenceEmailConfirm;
