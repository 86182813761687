import { useState } from 'react';
import validator from 'validator';

// react-router-dom components
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

// @mui material components
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from './FormField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

// Images
import bgImage from 'assets/images/taustakuva.jpg';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Footer from 'layouts/authentication/components/Footer';

import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { GenericResponse, FreeLicenceOrderRequest } from 'firebaseModels';
import Typography from '@mui/material/Typography';
import TermsAndConditions from './TermsAndConditions';
import LoadingButton from '@mui/lab/LoadingButton';

function FreeLicenceOrder(): JSX.Element {
  const theme = useTheme();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [providerData, setProviderData] = useState<FreeLicenceOrderRequest>({
    email: '',
    timezone,
  });
  const [checkBox, setCheckBox] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const functions = useFunctions();
  const free_licence_order = httpsCallable<
    FreeLicenceOrderRequest,
    GenericResponse
  >(functions, 'freelicenceordernew');
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { email } = providerData;

  const provideDataIsValid = email?.length > 2;

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    setError('');
    setSuccess(false);
    // console.log('providerData', providerData);
    free_licence_order({
      ...providerData,
    })
      .then((result) => {
        // console.log('result', result);
        if (result.data.success) {
          setSuccess(true);
        } else {
          // console.log('error', result.data.message);
          setError(result.data.message);
        }
      })
      .catch((error) => {
        // console.log('error', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageLayout>
      <MDBox position="relative" minHeight="100vh" width="100%" py={0} px={0}>
        <MDBox
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            minHeight: '100vh',
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }: Theme) =>
              bgImage &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <MDBox container py={10} px={2}>
          <Grid justifyContent="center" alignItems="center" container>
            <Grid item xs={11} xl={8}>
              <Card sx={{ maxWidth: 'lg', margin: 'auto' }}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Order Trial Licenses
                  </MDTypography>
                  <MDTypography
                    display="block"
                    variant="button"
                    color="white"
                    my={1}
                  >
                    A verification email will be sent to the email address
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <FormField
                        type="text"
                        label="Email"
                        name="email"
                        autoCapitalize="off"
                        error={'Email is required'}
                        success={
                          providerData.email?.length > 0 &&
                          validator.isEmail(providerData.email)
                        }
                        value={providerData.email}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            email: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        ml={-1}
                        mt={2}
                        mb={2}
                      >
                        <Checkbox
                          id="agree_checkbox"
                          checked={checkBox}
                          onChange={(e: any) => setCheckBox(e.target.checked)}
                          sx={{ color: 'info', height: '1em' }}
                        />
                        <MDTypography
                          component="label"
                          htmlFor="agree_checkbox"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: 'pointer', userSelect: 'none' }}
                        >
                          &nbsp;&nbsp;I agree to the&nbsp;
                          <MDTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                            onClick={handleClickOpen}
                          >
                            Terms and Conditions
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        display="flex"
                        mb={2}
                        sx={{
                          '& svg': {
                            color: 'info',
                            mr: 1,
                          },
                          mt: {
                            xs: -1,
                            md: 2,
                          },
                          mk: {
                            sx: -1,
                            md: 0,
                          },
                          justifyContent: {
                            xs: 'flex-start',
                            md: 'flex-end',
                          },
                        }}
                      >
                        <MDTypography variant="button">
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            size="lg"
                            color="#F40F02"
                          />
                          <MDTypography
                            component="a"
                            href="https://doc.oneirostherapies.com/Oneiros_clinical_and_technical_overview.pdf"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            target="_blank"
                            textGradient
                            ml={1}
                            sx={{ cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon
                              icon={faExternalLinkAlt}
                              color={theme.palette.info.main}
                            />
                            Is Oneiros safe to use?
                          </MDTypography>
                          &nbsp;&nbsp;(
                          <MDTypography
                            component="a"
                            href="https://doc.oneirostherapies.com/Oneiros_tekninen_ja_kliininen_kuvaus.pdf"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            target="_blank"
                            textGradient
                            sx={{ ml: 1, mr: 1, cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon
                              icon={faExternalLinkAlt}
                              color={theme.palette.info.main}
                            />
                            In Finnish
                          </MDTypography>
                          )
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                      {error && (
                        <>
                          <MDTypography
                            variant="h6"
                            color="error"
                            sx={{ mt: 3 }}
                          >
                            Error
                          </MDTypography>
                          <MDTypography variant="body1" color="error">
                            {error}
                          </MDTypography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {success ? (
                        <>
                          <MDTypography variant="h6" color="success">
                            Licence Request Sent Successfully
                          </MDTypography>
                          <MDTypography color="success">
                            Check your email for further instructions
                          </MDTypography>
                        </>
                      ) : (
                        <LoadingButton
                          loading={loading}
                          disabled={!provideDataIsValid || !checkBox}
                          onClick={handleSubmit}
                          size="large"
                          variant="outlined"
                          sx={{
                            color: 'white',
                            backgroundColor: (theme) => theme.palette.info.main,
                            '&:hover': {
                              borderColor: (theme) => theme.palette.info.main,
                              backgroundColor: (theme) =>
                                theme.palette.info.main,
                            },
                          }}
                        >
                          Order Now
                        </LoadingButton>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          <Footer light />
        </MDBox>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Terms and Conditions'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TermsAndConditions />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose} color="info">
            OK
          </MDButton>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}

export default FreeLicenceOrder;
