/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Avatar from '@mui/material/Avatar';

// Declaring props types for CustomerCell
interface Props {
  image?: string;
  name: string;
  color?:
    | 'transparent'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark';
}

function CustomerCell({ image, name, color }: Props): JSX.Element {
  return (
    <MDBox display="flex" alignItems="center">
      <MDTypography
        variant="caption"
        fontWeight="medium"
        color="text"
        sx={{ lineHeight: 0 }}
      >
        {name}
      </MDTypography>
    </MDBox>
  );
}

// Declaring default props for CustomerCell
CustomerCell.defaultProps = {
  image: '',
  color: 'dark',
};

export default CustomerCell;
