import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useFirestore,
  useFunctions,
  useFirestoreCollectionData,
} from 'reactfire';
import {
  collection,
  doc,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import Card from '@mui/material/Card';
// import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import DefaultCell from './components/DefaultCell';
import { useNavigate, useParams } from 'react-router-dom';
import ActionCell from './components/ActionCell';
import {
  Alert,
  CardActions,
  CardHeader,
  CircularProgress,
  Icon,
  Stack,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import MDTypography from 'components/MDTypography';
import { GenericResponse } from 'firebaseModels';
import { useServiceProvider } from 'routes';

type FetchStatus = 'idle' | 'loading' | 'success' | 'error';

type ReportDeleteRequest = {
  providerId: string;
  pdfFile: string;
  timezone: string;
};

type ReportDeleteResponse = {
  success: boolean;
  message: string;
};

type GetReportRequest = {
  startDate: string;
  endDate: string;
  providerId: string;
  timezone: string;
};

type GetReportResponse = {
  success: boolean;
  message: string;
  report: any;
};

function InvoiceList(): JSX.Element {
  const { providerId } = useParams();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [generationStatus, setGenerationStatus] = useState<FetchStatus>('idle');
  const [deletionStatus, setDeletionStatus] = useState<FetchStatus>('idle');
  const [message, setMessage] = useState('');
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);
  const [open, setOpen] = useState(false);
  const firestore = useFirestore();

  const providerRef = useMemo(
    () => doc(firestore, 'ServiceProvider', providerId),
    [firestore, providerId]
  );

  const { serviceProvider } = useServiceProvider(providerRef);

  const providerName = serviceProvider?.name || '?';

  const invoicesCollection = useMemo(
    () => collection(firestore, 'ServiceProvider', providerId, 'Reports'),
    [firestore, providerId]
  );
  const invoicesQuery = useMemo(
    () =>
      query(
        invoicesCollection,
        where('type', '==', 'invoicing'),
        orderBy('created', 'desc')
      ),
    [invoicesCollection]
  );

  const { status, data: reports } = useFirestoreCollectionData(invoicesQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });

  const functions = useFunctions();

  const get_invoicing_report = useCallback(() => {
    return httpsCallable<GetReportRequest, GenericResponse>(
      functions,
      'getinvoicingreport'
    );
  }, [functions]);

  const delete_report = useCallback(() => {
    return httpsCallable<ReportDeleteRequest, GenericResponse>(
      functions,
      'deleteinvoicingreport'
    );
  }, [functions]);

  const mark_as_billed = useCallback(() => {
    return httpsCallable<ReportDeleteRequest, GenericResponse>(
      functions,
      'markasbilled'
    );
  }, [functions]);

  const columns = useMemo<Array<Column>>(
    () => [
      {
        Header: 'start date',
        accessor: 'startDate',
        type: 'datetime',
        Cell: ({ value }: any) => (
          <DefaultCell value={value?.toLocaleDateString() || '*'} />
        ),
      },
      {
        Header: 'end date',
        accessor: 'endDate',
        type: 'datetime',
        Cell: ({ value }) => (
          <DefaultCell value={value?.toLocaleDateString() || '*'} />
        ),
      },
      {
        Header: 'download',
        accessor: 'downloadUrl',
        Cell: ({ value }) => (
          <MDButton
            iconOnly
            color="info"
            size="medium"
            variant="text"
            onClick={() => {
              window.open(value, '_blank');
            }}
          >
            <Icon>download</Icon>
            Download
          </MDButton>
        ),
      },
      {
        Header: 'billing date',
        accessor: 'billed',
        Cell: ({ value }) => {
          const { billed, pdfFile } = value;
          if (!!billed) {
            const str = billed?.toDate().toLocaleDateString();
            return <DefaultCell value={str} />;
          }
          return (
            <MDButton
              color="info"
              size="medium"
              disabled={!!billed}
              onClick={() => {
                setIsLoading(true);
                setMessage('');
                setGenerationStatus('loading');
                console.log('mark_as_billed', { providerId, pdfFile });
                mark_as_billed()({
                  providerId,
                  pdfFile,
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })
                  .then((result) => {
                    const { success, message } = result.data;
                    if (success) {
                      setGenerationStatus('success');
                      setMessage(message);
                      setErrorMessages([]);
                    } else {
                      setGenerationStatus('error');
                      setMessage('');
                      setErrorMessages([...errorMessages, message]);
                    }
                    setIsLoading(false);
                  })
                  .catch(() => {
                    setGenerationStatus('error');
                    setMessage('');
                    setErrorMessages([
                      ...errorMessages,
                      'Error marking as billed',
                    ]);
                    setIsLoading(false);
                  })
                  .finally(() => {
                    // trigger a refresh
                    setIsLoading(false);
                  });
              }}
            >
              <Icon>done</Icon>
              &nbsp;Mark as Billed
            </MDButton>
          );
        },
      },
      {
        Header: 'generated licences',
        accessor: 'generatedLicences',
        type: 'number',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: 'validated licences',
        accessor: 'validatedLicences',
        type: 'number',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: 'created by',
        accessor: 'createdBy',
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: 'actions',
        accessor: (row: any) => ({
          id: row.id,
          email: row.email,
          billed: row.billed,
          startDate: row.startDate,
          endDate: row.endDate,
        }),
        align: 'right',
        flex: 1,
        disableSortBy: true,
        Cell: ({ value: { startDate, endDate, id, billed } }) => (
          <ActionCell
            name={`${startDate?.toLocaleDateString() || '*'} - ${endDate?.toLocaleDateString() || '*'}`}
            deleteDisabled={isDeleting}
            isDeleting={isDeleting}
            noEdit={true}
            onDeleteConfirm={() => {
              setIsDeleting(true);
              setMessage('');
              setDeletionStatus('loading');
              delete_report()({
                providerId,
                pdfFile: id,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
                .then((result) => {
                  const { success, message } = result.data;
                  if (success) {
                    setDeletionStatus('success');
                    setMessage(message);
                    setErrorMessages([]);
                  } else {
                    setDeletionStatus('error');
                    setMessage('');
                    setErrorMessages([...errorMessages, message]);
                  }
                  setIsDeleting(false);
                })
                .catch(() => {
                  setDeletionStatus('error');
                  setErrorMessages([...errorMessages, 'Error deleting report']);
                  setIsDeleting(false);
                })
                .finally(() => {
                  // trigger a refresh
                  setIsLoading(false);
                });
            }}
          />
        ),
      },
    ],
    [delete_report, errorMessages, isDeleting, mark_as_billed, providerId]
  );

  const data = useMemo<Array<any>>(
    () =>
      (reports || []).map((p) => {
        const {
          id,
          startDate,
          endDate,
          downloadUrl,
          pdfFile,
          billed,
          generatedLicences,
          validatedLicences,
          createdBy,
        } = p;
        return {
          id,
          createdBy,
          startDate: startDate ? startDate.toDate() : null,
          endDate: endDate ? endDate.toDate() : null,
          downloadUrl,
          pdfFile,
          generatedLicences,
          validatedLicences,
          billed: { billed, pdfFile },
        };
      }),
    [reports]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy: [{ id: 'startDate' }] },
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOK = useCallback(() => {
    setIsLoading(true);
    setMessage('');
    setGenerationStatus('loading');
    // date as YYYY-MM-DD
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    get_invoicing_report()({
      startDate: startDate?.toISOString() || '',
      endDate: endDate?.toISOString() || '',
      providerId,
      timezone,
    })
      .then((result) => {
        const { success, message } = result.data;
        if (success) {
          setGenerationStatus('success');
          setMessage(message);
          setErrorMessages([]);
        } else {
          setGenerationStatus('error');
          setMessage('');
          setErrorMessages([...errorMessages, message]);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setGenerationStatus('error');
        setMessage('');
        setErrorMessages([...errorMessages, 'Error generating report']);
        setIsLoading(false);
      })
      .finally(() => {
        setOpen(false);
      });
  }, [endDate, errorMessages, get_invoicing_report, providerId, startDate]);

  if (status === 'loading' || isLoading) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress />
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <MDBox display="flex">
            <MDButton
              variant="contained"
              color="info"
              onClick={() => setOpen(true)}
            >
              Generate Invoice Report
            </MDButton>
          </MDBox>
        </MDBox>
        <Card>
          <CardHeader title={`Invoice Reports for ${providerName}`} />
          {!reports || reports.length === 0 ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={10}
            >
              No invoice reports found
            </MDBox>
          ) : (
            <DataTable
              key="user-list"
              tableInstance={tableInstance}
              entriesPerPage={{
                defaultValue: 50,
                entries: [50, 100, 200, 500, 1000],
              }}
              canSearch
            />
          )}
        </Card>
        {message && (
          <Card
            sx={{
              padding: 2,
              marginTop: 2,
            }}
          >
            <Alert severity="success">{message}</Alert>
          </Card>
        )}
        {errorMessages.length > 0 && (
          <Card
            sx={{
              padding: 2,
              marginTop: 2,
            }}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              {errorMessages.map((message, index) => (
                <Alert variant="filled" severity="error">
                  {message}
                </Alert>
              ))}
            </Stack>
          </Card>
        )}
      </MDBox>
      <Footer />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Generate Invoice Report'}
          </DialogTitle>
          <DialogContent sx={{ minWidth: 400 }}>
            <MDBox>
              <MDBox display="flex" alignItems="center" mb={2} mt={2}>
                <MDBox mr={2} sx={{ minWidth: 100 }}>
                  <MDTypography variant="h6">Start Date:</MDTypography>
                </MDBox>
                <DatePicker
                  value={startDate || null}
                  label="Start Date"
                  slotProps={{
                    textField: {
                      helperText: 'Empty = since beginning of time',
                    },
                  }}
                  onChange={(newValue) => setStartDate(newValue)}
                />
                {/* <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                /> */}
              </MDBox>
              <MDBox display="flex" alignItems="center" mb={2}>
                <MDBox mr={2} sx={{ minWidth: 100 }}>
                  <MDTypography variant="h6">End Date:</MDTypography>
                </MDBox>
                <DatePicker
                  label="End Date"
                  value={endDate || null}
                  slotProps={{
                    textField: {
                      helperText: 'Empty = until now',
                    },
                  }}
                  onChange={(newValue) => setEndDate(newValue)}
                />
                {/* <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                /> */}
              </MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleOK} color="info" autoFocus>
              OK
            </MDButton>
            <MDButton onClick={handleClose} color="error">
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </DashboardLayout>
  );
}

export default InvoiceList;
