/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Provider Page: https://www.creative-tim.com/Provider/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import {
  CountryCode,
  parsePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import FormField from './FormField';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// NewProvider page components
import ProviderInfo from './ProviderInfo';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { useNavigate, useParams } from 'react-router-dom';
import { ProviderData, ProviderDataProps } from './NewProvider';
import {
  Alert,
  AlertTitle,
  CardContent,
  CircularProgress,
} from '@mui/material';
import PortalUsers from './PortalUsers';

export type PortalUser = {
  id: string;
  email: string;
  address: string;
  phone: string;
  name: string;
  level: string;
  modified: string | null;
  created: string | null;
  modifiedBy: string;
  createdBy: string;
};

type ProviderResponse = {
  success: boolean;
  provider: ProviderData;
  portalUsers: PortalUser[];
};

type EditProviderStatus = {
  status: 'idle' | 'loading' | 'saving' | 'success' | 'error';
  message: string;
};

type Props = {
  trial?: boolean;
};
function EditProvider({ trial = false }: Props): JSX.Element {
  const { providerId } = useParams();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [providerData, setProviderData] = useState<ProviderData | null>(null);
  const [portalUsers, setPortalUsers] = useState<PortalUser[]>([]);
  const [getProviderStatus, setGetProviderStatus] = useState<string>('idle');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [isComplete, setIsComplete] = useState(false);
  const [editStatus, setEditStatus] = useState<EditProviderStatus>({
    status: 'idle',
    message: '',
  });
  const functions = useFunctions();
  const getServiceProvider = useMemo(
    () => httpsCallable(functions, 'getserviceprovider'),
    [functions]
  );
  const updateServiceProvider = useMemo(
    () => httpsCallable(functions, 'updateserviceprovider'),
    [functions]
  );

  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleSave = useCallback(async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      setEditStatus({
        status: 'saving',
        message: 'Saving...',
      });
    }
  }, [isSubmitting]);

  const getProvider = useCallback(async () => {
    try {
      const provider = await getServiceProvider({ providerId, timezone });
      const providerResponse = (provider?.data || '{}') as ProviderResponse;
      const providerData = {
        id: providerId,
        ...providerResponse.provider,
        invoice_address_is_same_as_main:
          providerResponse.provider.invoice_address_is_same_as_main !== false,
      };
      setProviderData(providerData);
      setPortalUsers(providerResponse.portalUsers || []);
      if (providerResponse.success) {
        setGetProviderStatus('success');
        setEditStatus({
          status: 'idle',
          message: '',
        });
      } else {
        setGetProviderStatus('error');
        setEditStatus({
          status: 'error',
          message: 'Error getting provider',
        });
      }
    } catch (error: any) {
      setGetProviderStatus('error');
      console.error(error);
      setEditStatus({
        status: 'error',
        message: error.message,
      });
    }
  }, [getServiceProvider, providerId, timezone]);

  const onActivateProvider = useCallback(() => {
    setProviderData({
      ...providerData,
      trialProvider: false,
    });
    setIsSubmitting(true);
    setNavigateTo('/providers');
  }, [providerData]);

  useEffect(() => {
    setGetProviderStatus('loading');
    getProvider();
  }, [getProvider]);

  useEffect(() => {
    if (isSubmitting) {
      console.log('submit providerData', providerData);
      updateServiceProvider({
        providerId: providerData?.id,
        name: providerData.name,
        description: providerData.description,
        address: providerData.address,
        address2: providerData.address2,
        city: providerData.city,
        state: providerData.state,
        zipCode: providerData.zipCode,
        country: providerData.country,
        email: providerData.email,
        phone: providerData.phone,
        planId: providerData.subscriptionPlan.id,
        contact_first_name: providerData.contact_first_name,
        contact_last_name: providerData.contact_last_name,
        contact_initials: providerData.contact_initials,
        contact_title: providerData.contact_title,
        contact_organization_role: providerData.contact_organization_role,
        contact_email: providerData.contact_email,
        contact_phone: providerData.contact_phone,
        trialProvider: providerData.trialProvider,
        invoice_email: providerData.invoice_email,
        invoice_address: providerData.invoice_address,
        invoice_address2: providerData.invoice_address2,
        invoice_city: providerData.invoice_city,
        invoice_state: providerData.invoice_state,
        invoice_zipCode: providerData.invoice_zipCode,
        invoice_country: providerData.invoice_country,
        invoice_organization_name: providerData.invoice_organization_name,
        invoice_vat_number: providerData.invoice_vat_number,
        invoice_address_is_same_as_main:
          providerData.invoice_address_is_same_as_main,
        maxLicences: providerData.maxLicences,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
        .then((result) => {
          const data = (result?.data || '{}') as ProviderDataProps;
          const { success, message } = data;
          if (!success) {
            setEditStatus({
              status: 'error',
              message,
            });
            return;
          } else {
            if (navigateTo) {
              navigate(navigateTo);
            }
            setEditStatus({
              status: 'success',
              message,
            });
            getProvider();
          }
        })
        .catch((error) => {
          setEditStatus({
            status: 'error',
            message: error.message,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsComplete(true);
        });
    }
  }, [
    functions,
    isSubmitting,
    providerData,
    navigate,
    updateServiceProvider,
    getProvider,
    navigateTo,
  ]);

  const { trialProvider } = providerData || {};

  const {
    providerDataIsValid,
    trialProviderDataIsValid,
    mainAddressIsValid,
    invoiceAddressIsValid,
    phoneNumberIsValid,
  } = validateProviderData(providerData);

  // console.log('providerData', providerData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {getProviderStatus === 'error' ? (
        <Alert severity="error" variant="filled" sx={{ m: 2 }}>
          <AlertTitle>Error</AlertTitle>
          {'Error getting provider: '}
          {editStatus.message}
        </Alert>
      ) : (
        <MDBox py={3} px={{ xs: 3, sm: 6, xl: 12 }}>
          <MDTypography
            variant="h3"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Edit {trial ? 'Trial ' : ''}Provider
          </MDTypography>
          <MDBox mt={3}>
            <Card>
              <CardContent>
                {getProviderStatus === 'loading' && (
                  <MDBox display="flex" justifyContent="center" py={3}>
                    <CircularProgress />
                  </MDBox>
                )}
                {providerData && (
                  <>
                    <ProviderInfo
                      providerData={providerData}
                      setProviderData={setProviderData}
                      invoiceAddressIsValid={invoiceAddressIsValid}
                      mainAddressIsValid={mainAddressIsValid}
                      phoneNumberIsValid={phoneNumberIsValid}
                    />
                    {editStatus.status === 'error' && (
                      <MDBox mt={3}>
                        <MDTypography variant="h6" color="error">
                          {"Error saving provider's data:"}
                        </MDTypography>
                        <MDTypography variant="body1" color="error">
                          {editStatus.message}
                        </MDTypography>
                      </MDBox>
                    )}
                    {editStatus.status === 'success' && (
                      <MDBox mt={3}>
                        <MDTypography variant="body1" color="success">
                          {editStatus.message}
                        </MDTypography>
                      </MDBox>
                    )}

                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      mb={3}
                      mr={3}
                    >
                      {trialProvider && (
                        <>
                          <MDButton
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              openDialog();
                            }}
                            disabled={isSubmitting || !providerDataIsValid}
                          >
                            Turn into Full Provider
                          </MDButton>
                          <Dialog
                            open={dialogOpen}
                            onClose={closeDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              Please enter admin username and password
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                <p>This action cannot be undone.</p>
                              </DialogContentText>
                              <br />
                              <p>
                                <FormField
                                  label="Username (email)"
                                  value={providerData.admin_email || ''}
                                  onChange={(e: any) =>
                                    setProviderData({
                                      ...providerData,
                                      admin_email: e.target.value,
                                    })
                                  }
                                />
                              </p>
                              <br />
                              <DialogContentText id="alert-dialog-description">
                                <p>
                                  An invitation will be sent to the email
                                  address.
                                </p>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <MDButton onClick={closeDialog} color="info">
                                Cancel
                              </MDButton>
                              <MDButton
                                onClick={() => {
                                  onActivateProvider();
                                  closeDialog();
                                }}
                                color="error"
                                autoFocus
                              >
                                Confirm
                              </MDButton>
                            </DialogActions>
                          </Dialog>
                          &nbsp;
                        </>
                      )}
                      <MDButton
                        variant="outlined"
                        color="dark"
                        onClick={() =>
                          navigate('/providers' + (trial ? '/trial' : ''))
                        }
                        disabled={isSubmitting}
                      >
                        Cancel
                      </MDButton>
                      &nbsp;
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={handleSave}
                        disabled={
                          isSubmitting ||
                          (!trialProvider && !providerDataIsValid) ||
                          (trialProvider && !trialProviderDataIsValid)
                        }
                      >
                        Save
                      </MDButton>
                    </MDBox>
                  </>
                )}
              </CardContent>
            </Card>
          </MDBox>
          {!trial && getProviderStatus !== 'loading' && portalUsers && (
            <MDBox mt={3}>
              <Card>
                <CardContent>
                  <PortalUsers
                    portalUsers={portalUsers}
                    providerId={providerId}
                    refreshFunc={getProvider}
                  />
                </CardContent>
              </Card>
            </MDBox>
          )}
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default EditProvider;

export function validateProviderData(providerData: ProviderData) {
  const { invoice_address_is_same_as_main } = providerData || {};

  const trialProviderDataIsValid =
    providerData?.name &&
    providerData?.contact_email &&
    validator.isEmail(providerData?.contact_email);
  // providerData?.contact_first_name?.length > 2 &&
  // providerData?.contact_last_name?.length > 2 &&
  // providerData?.contact_phone?.length > 2 &&
  // providerData?.country !== null &&
  // (!providerData?.zipCode ||
  //   validator.isPostalCode(providerData?.zipCode, 'any')) &&
  // validator.isMobilePhone(providerData?.contact_phone);

  const mainAddressIsValid = !(
    (providerData?.address ||
      providerData?.city ||
      providerData?.state ||
      providerData?.zipCode) &&
    (!providerData?.address ||
      !providerData?.city ||
      providerData?.state?.length <= 1 ||
      !providerData?.zipCode ||
      (providerData?.zipCode &&
        !validator.isPostalCode(providerData?.zipCode, 'any')))
  );

  const invoiceAddressIsValid = invoice_address_is_same_as_main
    ? mainAddressIsValid
    : !(
        (providerData?.invoice_organization_name ||
          providerData?.invoice_address ||
          providerData?.invoice_city ||
          providerData?.invoice_state ||
          providerData?.invoice_zipCode ||
          providerData?.invoice_country) &&
        (!providerData?.invoice_organization_name ||
          !providerData?.invoice_address ||
          !providerData?.invoice_city ||
          providerData?.invoice_state?.length <= 1 ||
          !providerData?.invoice_country ||
          !providerData?.invoice_zipCode ||
          (providerData?.invoice_zipCode &&
            !validator.isPostalCode(providerData?.invoice_zipCode, 'any')))
      );

  let phoneNumber = providerData?.contact_phone;
  const countryCode = providerData?.country?.code as CountryCode | undefined;
  try {
    phoneNumber = parsePhoneNumber(
      providerData?.contact_phone,
      countryCode
    ).formatInternational();
  } catch (e) {}

  const phoneNumberIsValid =
    phoneNumber &&
    validatePhoneNumberLength(phoneNumber, countryCode) === undefined &&
    isValidPhoneNumber(phoneNumber, countryCode);

  const providerDataIsValid =
    trialProviderDataIsValid &&
    mainAddressIsValid &&
    invoiceAddressIsValid &&
    phoneNumberIsValid &&
    (!providerData?.invoice_vat_number ||
      (providerData?.invoice_country &&
        validator.isVAT(
          providerData?.invoice_vat_number,
          providerData?.invoice_country.code
        ))) &&
    (!providerData?.invoice_email ||
      validator.isEmail(providerData?.invoice_email));

  return {
    providerDataIsValid,
    trialProviderDataIsValid,
    mainAddressIsValid,
    invoiceAddressIsValid,
    phoneNumberIsValid,
  };
}
