import { useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import Autocomplete from '@mui/material/Autocomplete';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import IdCell from 'layouts/ecommerce/orders/order-list/components/IdCell';
import DefaultCell from 'layouts/ecommerce/orders/order-list/components/DefaultCell';
import Typography from '@mui/material/Typography';
import { CSVLink } from 'react-csv';
import { useAuth, useFirestore, useFirestoreCollectionData } from 'reactfire';
import {
  collection,
  doc,
  updateDoc,
  orderBy,
  query,
  where,
  limit,
  QueryConstraint,
} from 'firebase/firestore';
import SubscriptionPlanCell from './components/SubscriptionPlanCell';
import { usePortalUser } from 'routes';
import CheckboxCell from './components/CheckboxCell';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { CardContent, CardHeader, CircularProgress } from '@mui/material';
import { PortalUser } from './EditProvider';
import { ServiceProvider } from 'firebaseModels';
import MDInput from 'components/MDInput';
import { To, useNavigate, useParams } from 'react-router-dom';

/**
 * Display list of /UserLog/{licenceId}/Events for a specific licence
 */
export default function LicenceUserLog(): JSX.Element {
  const { licenceId } = useParams();

  const firestore = useFirestore();

  const eventsCollection = useMemo(
    () => collection(firestore, 'UserLog', licenceId, 'Events'),
    [firestore, licenceId]
  );

  const eventsQuery = useMemo(
    () =>
      query(
        eventsCollection,
        null,

        orderBy('timestamp', 'desc')
      ),
    [eventsCollection]
  );

  const { data: events, status } = useFirestoreCollectionData(eventsQuery, {
    idField: 'id',
  });

  const dataTableData = useMemo(() => {
    let columns: any = [
      {
        Header: 'Entry Type',
        accessor: 'entryType',
        Cell: ({ value }: { value: any }) => {
          return <DefaultCell value={value} />;
        },
      },
      {
        Header: 'Event',
        accessor: 'entryData',
        Cell: ({ value }: { value: any }) => {
          return <DefaultCell value={value.event} />;
        },
      },
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell: ({ value }: { value: any }) => {
          return <DefaultCell value={value.toDate().toLocaleString()} />;
        },
      },
    ];

    return {
      columns,
      rows:
        events?.map((event: any) => {
          return {
            ...event,
          };
        }) || [],
    };
  }, [events]);

  const tableInstance = useTable(
    {
      columns: dataTableData.columns,
      data: dataTableData.rows,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} mx={3}>
        <Card>
          <CardHeader title="User Log" />
          <CardContent>
            {status === 'loading' ? (
              <CircularProgress />
            ) : (
              <DataTable tableInstance={tableInstance} />
            )}
          </CardContent>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
