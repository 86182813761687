import { createRoot } from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';
import App from 'App';

import { MaterialUIControllerProvider } from 'context';
import { firebaseConfig } from './firebaseConfig';

const root = createRoot(document.getElementById('root'));

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </FirebaseAppProvider>
);
