import { useSigninCheck } from 'reactfire';
import { Navigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useMemo } from 'react';

/**
 * A wrapper component that redirects to the login page if the user is not logged in.
 *
 * @param  JSX.Element children
 * @returns JSX.Element
 */
export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();
  let location = useLocation();
  const state = useMemo(() => ({ from: location }), [location]);

  if (!signInCheckResult || authStatus === 'loading') {
    return <CircularProgress />;
  }

  if (!signInCheckResult.signedIn) {
    return <Navigate to="/signin" state={state} replace />;
  }

  return children;
}
