import { useFunctions } from 'reactfire';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import DataTable from 'examples/Tables/DataTable';
import CustomerCell from './components/CustomerCell';
import DefaultCell from './components/DefaultCell';
import SubscriptionPlanCell from './components/SubscriptionPlanCell';
import { PortalUser } from './EditProvider';
import { useCallback, useMemo, useState } from 'react';
import { CardContent, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ActionCell from './components/ActionCell';
import { CircularProgress } from '@mui/material';
import { httpsCallable } from 'firebase/functions';

type PortalUsersProps = {
  portalUsers: PortalUser[];
  providerId: string;
  refreshFunc: Function;
};

type FetchStatus = 'idle' | 'loading' | 'success' | 'error';

type UserDeleteRequest = {
  email: string;
  timezone: string;
};

type UserDeleteResponse = {
  success: boolean;
  message: string;
};

export default function PortalUsers(props: PortalUsersProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const functions = useFunctions();

  const { portalUsers, providerId } = props;
  const delete_user = useCallback(() => {
    return httpsCallable<UserDeleteRequest, UserDeleteResponse>(
      functions,
      'deleteportaluser'
    );
  }, [functions]);

  const columns = useMemo<Array<Column>>(
    () => [
      {
        Header: 'email',
        accessor: 'id',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'name',
        accessor: 'name',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: 'actions',
        accessor: (row: any) => ({ id: row.id, email: row.email }),
        align: 'right',
        disableSortBy: true,
        Cell: ({ value: { email, id } }) => (
          <ActionCell
            name={email}
            deleteDisabled={isDeleting}
            isDeleting={isDeleting}
            onEditClick={() => {
              navigate(`/providers/${providerId}/users/${id}`);
            }}
            onDeleteConfirm={() => {
              setIsDeleting(true);
              delete_user()({
                email,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              })
                .then((result) => {
                  setIsDeleting(false);
                })
                .catch(() => {
                  setIsDeleting(false);
                })
                .finally(() => {
                  // trigger a refresh
                  props.refreshFunc();
                });
            }}
          />
        ),
      },
    ],
    []
  );

  const data = useMemo<Array<any>>(
    () =>
      portalUsers?.map((p) => {
        const { id, email, name, level, created } = p as PortalUser;
        return {
          id,
          email,
          name,
          level,
          created: created ? new Date(created).toLocaleDateString() : '',
        };
      }) || [],
    [portalUsers]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      initialState: { pageIndex: 0, sortBy: [{ id: 'name' }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <MDBox>
      <MDBox mt={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Provider's Portal Users</MDTypography>
        </MDBox>
        <DataTable
          key={`portal-users-${providerId}`}
          tableInstance={tableInstance}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </MDBox>
      <MDBox mt={3}>
        <MDButton
          variant="gradient"
          color="info"
          onClick={() => {
            navigate(`/providers/${providerId}/newuser`);
          }}
        >
          add new
        </MDButton>
      </MDBox>
    </MDBox>
  );
}
