// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import MDEditor from 'components/MDEditor';
import MDBadge from 'components/MDBadge';

// NewLicence page components
import { LicenceGenerationData } from './NewLicence';

function LicenceConfirm({
  licenceGenerationData,
}: {
  licenceGenerationData: LicenceGenerationData;
}): JSX.Element {
  const {
    plan,
    description,
    numberOfLicences,
    questionnaires,
    questionnairesOnByDefault,
  } = licenceGenerationData;

  return (
    <MDBox>
      <MDTypography variant="h5">Confirm Generation</MDTypography>
      <MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h3" fontWeight="bold">
            Licence Details
          </MDTypography>
        </MDBox>
        <MDBox mt={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Number of licenses to create
          </MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {numberOfLicences}
          </MDTypography>
        </MDBox>
        <MDBox mt={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Questionnaires
          </MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {questionnaires ? 'Yes' : 'No'}
          </MDTypography>
        </MDBox>
        <MDBox mt={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Questionnaires on by Default
          </MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {questionnairesOnByDefault ? 'Yes' : 'No'}
          </MDTypography>
        </MDBox>
        <MDBadge
          variant="contained"
          color="success"
          badgeContent={plan.name}
          container
        />
        {description && (
          <>
            <MDBox mt={3} mb={1} ml={0.5}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                Description
              </MDTypography>
            </MDBox>
            <MDBox mb={2} ml={0.5}>
              <MDTypography
                variant="body2"
                color="text"
                fontWeight="regular"
                verticalAlign="middle"
              >
                {description}
              </MDTypography>
            </MDBox>
          </>
        )}
      </MDBox>
    </MDBox>
  );
}

export default LicenceConfirm;
