/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Licence Page: https://www.creative-tim.com/Licence/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import MDEditor from 'components/MDEditor';
import MDInput from 'components/MDInput';

// NewLicence page components
import FormField from './FormField';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, orderBy, query } from 'firebase/firestore';
import { SubscriptionPlan } from 'firebaseModels';
import { LicenceGenerationData } from './NewLicence';
import { CircularProgress, FormControlLabel } from '@mui/material';

function LicenceInfo({
  licenceGenerationData,
  setLicenceGenerationData,
}: {
  licenceGenerationData: LicenceGenerationData;
  setLicenceGenerationData: Function;
}): JSX.Element {
  const firestore = useFirestore();
  const plansCollection = collection(firestore, 'SubscriptionPlan');
  const plansQuery = query(plansCollection, orderBy('name', 'asc'));
  const {
    plan,
    description,
    numberOfLicences,
    questionnaires,
    questionnairesOnByDefault,
  } = licenceGenerationData;
  const { status, data: plans } = useFirestoreCollectionData(plansQuery, {
    idField: 'id', // this field will be added to the object created from each document
  });
  useEffect(() => {
    if (status === 'success' && plans.length > 0 && !plan) {
      setLicenceGenerationData({
        plan: plans[0] as SubscriptionPlan,
        description: '',
        numberOfLicences: 1,
        questionnaires: true,
        questionnairesOnByDefault: false,
      });
    }
  }, [plan, plans, setLicenceGenerationData, status]);

  const updateData = useCallback(
    (licenceGenerationData: LicenceGenerationData) => {
      const {
        plan,
        description,
        numberOfLicences,
        questionnaires,
        questionnairesOnByDefault,
      } = licenceGenerationData;
      setLicenceGenerationData({
        plan,
        description,
        numberOfLicences,
        questionnaires,
        questionnairesOnByDefault,
      });
    },
    [setLicenceGenerationData]
  );

  if (status === 'loading') {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress />
      </MDBox>
    );
  }

  return (
    <MDBox>
      <MDTypography variant="h5">Licence Information</MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mb={0} display="inline-block">
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                #
              </MDTypography>
            </MDBox>
            <FormField
              error={numberOfLicences < 1 || numberOfLicences > 100}
              helperText={
                numberOfLicences < 1 || numberOfLicences > 100
                  ? 'Number of licenses must be between 1 and 100'
                  : ''
              }
              type="number"
              label="Number of Licences to Generate"
              value={numberOfLicences}
              onChange={(e: any) => {
                updateData({
                  ...licenceGenerationData,
                  numberOfLicences: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mb={2} display="inline-block">
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Plan
              </MDTypography>
            </MDBox>
            {plans && plans.length > 0 && (
              <Autocomplete
                disableClearable
                options={plans as SubscriptionPlan[]}
                getOptionLabel={(option: SubscriptionPlan) => option.name}
                renderInput={(params) => {
                  return <MDInput variant="standard" {...params} />;
                }}
                onChange={(e: any, value: SubscriptionPlan) => {
                  updateData({
                    ...licenceGenerationData,
                    plan: value,
                  });
                }}
                defaultValue={plans[0] as SubscriptionPlan}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mb={0} display="inline-block">
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={questionnaires}
                    onChange={(e: any) => {
                      updateData({
                        ...licenceGenerationData,
                        questionnaires: e.target.checked,
                      });
                    }}
                  />
                }
                label="Questionnaires"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mb={0} display="inline-block">
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={questionnairesOnByDefault}
                    onChange={(e: any) => {
                      updateData({
                        ...licenceGenerationData,
                        questionnairesOnByDefault: e.target.checked,
                      });
                    }}
                  />
                }
                label="Questionnaires On By Default"
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type="text"
              label="Description (optional)"
              value={description || ''}
              onChange={(e: any) => {
                updateData({
                  ...licenceGenerationData,
                  description: e.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default LicenceInfo;
