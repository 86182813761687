import { ChangeEvent, useCallback, useEffect, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/taustakuva.jpg';
import MDAlert from 'components/MDAlert';
import { useAuth, useSigninCheck } from 'reactfire';
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

function SignIn(): JSX.Element {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleForgotPassword = (e: any) => {
    e.preventDefault();
    navigate('/forgot-password');
  };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const { status, data: signInCheckResult } = useSigninCheck();
  const [signingIn, setSigningIn] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    // already logged in?
    if (status === 'success' && signInCheckResult?.signedIn) {
      navigate('/');
    }
  }, [status, navigate, signInCheckResult?.signedIn]);

  const login = useCallback(() => {
    setError(null);
    setSigningIn(true);
    setPersistence(
      auth,
      rememberMe ? browserLocalPersistence : browserSessionPersistence
    )
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            setSigningIn(false);
          })
          .catch((error) => {
            setError(error);
            setSigningIn(false);
          });
      })
      .catch((error) => {
        setError(error);
        setSigningIn(false);
      });
  }, [auth, email, password, rememberMe]);

  return status === 'loading' ? (
    <CircularProgress />
  ) : (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        {error && (
          <MDBox px={3}>
            <MDAlert color="error" mb={4}>
              {error.message}
            </MDAlert>
          </MDBox>
        )}

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                variant="outlined"
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                variant="outlined"
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                color="info"
                fullWidth
                variant="contained"
                loading={signingIn}
                disabled={signingIn}
                size="large"
                onClick={login}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  onClick={handleForgotPassword}
                >
                  Forgot password
                </MDTypography>
              </MDTypography>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{' '}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up/cover"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
