// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import MDEditor from 'components/MDEditor';
import MDBadge from 'components/MDBadge';
import { PortalUser } from 'firebaseModels';

function NewUserConfirm({ userData }: { userData: PortalUser }): JSX.Element {
  const { name, email, level } = userData;

  return (
    <MDBox>
      <MDTypography variant="h5">Confirm Generation</MDTypography>
      <MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h3" fontWeight="bold">
            User Details
          </MDTypography>
        </MDBox>
        <MDBox mt={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Email
          </MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {email}
          </MDTypography>
        </MDBox>
        <MDBox mt={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Name
          </MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {name}
          </MDTypography>
        </MDBox>
        <MDBadge
          variant="contained"
          color="success"
          badgeContent={level}
          container
        />
      </MDBox>
    </MDBox>
  );
}

export default NewUserConfirm;
