import { CircularProgress } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { DocumentReference } from 'firebase/firestore';
import { SubscriptionPlan } from 'firebaseModels';
import { useFirestoreDocData } from 'reactfire';

// Declaring props types for DefaultCell
interface Props {
  reference: DocumentReference<SubscriptionPlan>;
}

function SubscriptionPlanCell({ reference }: Props): JSX.Element {
  const { status, data: sp } = useFirestoreDocData(reference);
  const subscriptionPlan = sp as SubscriptionPlan;

  if (status === 'loading') {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress />
      </MDBox>
    );
  }

  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {subscriptionPlan.name}
    </MDTypography>
  );
}

export default SubscriptionPlanCell;
