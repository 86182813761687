function TermsAndConditions() {
  return (
    <div style={{ padding: '20px' }}>
      <ol>
        <li>
          <h2>Purpose of Trial</h2>
          <p>
            The purpose of the trial is solely for evaluation purposes to
            determine the suitability of the Oneiros mobile app for my sleep
            care needs.
          </p>
        </li>

        <li>
          <h2>Confidentiality</h2>
          <p>
            I agree to treat any information obtained during the trial period as
            confidential and not to disclose, reproduce, or use it for any
            purpose other than the intended evaluation.
          </p>
        </li>

        <li>
          <h2>Limited Access</h2>
          <p>
            Access to the Oneiros mobile app is limited to the trial period, and
            any features or functionalities provided during this period are
            subject to change.
          </p>
        </li>

        <li>
          <h2>Data Retention</h2>
          <p>
            Any data entered or generated during the trial period may be
            retained for analytical purposes. However, Oneiros ensures that no
            personally identifiable information is shared or used without
            explicit consent.
          </p>
        </li>

        <li>
          <h2>Feedback and Suggestions</h2>
          <p>
            Oneiros welcomes feedback, suggestions, and observations of problems
            during the trial period in order to improve the performance and
            features of the mobile application.
          </p>
        </li>

        <li>
          <h2>Termination of Trial</h2>
          <p>
            Oneiros has the right to terminate the trial at any time for any
            reason after the 3-month trial period. I agree that after
            termination, my access to the Oneiros mobile application will be
            revoked.
          </p>
        </li>

        <li>
          <h2>Conversion to Full License</h2>
          <p>
            Upon completion of the trial period, I or my employer have the
            option to convert to a full license agreement based on mutually
            agreed-upon terms.
          </p>
        </li>
        <li>
          <h2>Payment Terms</h2>
          <p>
            I undertake to pay for the licences I have ordered in accordance
            with the price list. The price of the licenses will be indicated on
            the order page when creating the order. An invoice will be sent to
            the email address you provide each month in arrears. If there are
            very few invoices to be paid, the invoice can be combined with the
            next invoice and invoiced several months in arrears. The payment
            period is 14 days. VAT will be added to the prices according to the
            requirements of each region.
          </p>
        </li>
        <li>
          <h2>Compliance with Laws</h2>
          <p>
            Both parties agree to comply with all applicable laws and
            regulations during the trial period.
          </p>
        </li>

        <li>
          <h2>Acceptance of Terms</h2>
          <p>
            By initiating the trial period, I acknowledge and agree to these
            trial usage terms.
          </p>
        </li>
      </ol>
    </div>
  );
}

export default TermsAndConditions;
