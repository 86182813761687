/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Provider Page: https://www.creative-tim.com/Provider/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useState } from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// NewProvider page components
import { PortalUser } from 'firebaseModels';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { useNavigate, useParams } from 'react-router-dom';
import UserInfo from './UserInfo';
import NewUserConfirm from './NewUserConfirm';

function getSteps(): string[] {
  return ['1. Provider Info', '2. Confirm'];
}

function getStepContent(
  stepIndex: number,
  userData: PortalUser,
  setUserData: Function
): JSX.Element {
  switch (stepIndex) {
    case 0:
      return <UserInfo userData={userData} setUserData={setUserData} />;
    case 1:
      return <NewUserConfirm userData={userData} />;
    default:
      return null;
  }
}
type NewUserCreationStatus = {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
};

export type UserDataProps = {
  success: boolean;
  message: string;
  id: string;
};

function NewUser(): JSX.Element {
  const { providerId } = useParams();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [userData, setUserData] = useState<PortalUser>({
    name: '',
    email: '',
    level: 'user',
    provider_id: providerId,
  });
  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;
  const [stepDataIsValid, setStepDataIsValid] = useState<boolean>(false);
  const [createStatus, setCreateStatus] = useState<NewUserCreationStatus>({
    status: 'idle',
    message: '',
  });
  const functions = useFunctions();
  const add_portal_user = httpsCallable(functions, 'addportaluser');

  useEffect(() => {
    if (userData.name && userData.email) {
      setStepDataIsValid(true);
    } else {
      setStepDataIsValid(false);
    }
  }, [userData.email, userData.name]);

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleConfirm = useCallback(() => {
    setCreateStatus({ status: 'loading', message: '' });
    add_portal_user({
      name: userData.name,
      email: userData.email,
      level: userData.level,
      provider_id: userData.provider_id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((result) => {
        setCreateStatus({ status: 'success', message: '' });
        navigate(-1);
      })
      .catch((error) => {
        setCreateStatus({ status: 'error', message: error.message });
      });
  }, [
    add_portal_user,
    navigate,
    userData.email,
    userData.level,
    userData.name,
    userData.provider_id,
  ]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New User
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep, userData, setUserData)}
                </MDBox>
                {createStatus.status === 'error' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="error">
                      {createStatus.message}
                    </MDTypography>
                  </MDBox>
                )}
                {createStatus.status === 'success' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="success">
                      Successfully created user
                    </MDTypography>
                  </MDBox>
                )}
                {createStatus.status === 'loading' && (
                  <MDBox mt={3}>
                    <MDTypography variant="body1" color="text">
                      Creating user...
                    </MDTypography>
                  </MDBox>
                )}

                <MDBox>
                  <MDBox
                    mt={3}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        back
                      </MDButton>
                    )}
                    <MDButton
                      disabled={
                        createStatus.status === 'loading' || !stepDataIsValid
                      }
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : handleConfirm}
                    >
                      {isLastStep ? 'confirm' : 'next'}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
