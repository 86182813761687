/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Provider Page: https://www.creative-tim.com/Provider/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect, useMemo, useState } from 'react';
import validator from 'validator';

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import FormField from './FormField';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// NewProvider page components
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { To, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { PortalUser } from 'firebaseModels';
import UserInfo from './UserInfo';

export interface EditUserStatus {
  status: 'idle' | 'saving' | 'success' | 'error';
  message: string;
}

export interface UserDataProps {
  success: boolean;
  message: string;
}

function EditUser(): JSX.Element {
  const { userId } = useParams();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [userData, setUserData] = useState<PortalUser | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [navigateTo, setNavigateTo] = useState<To>(-1 as To);
  const [isComplete, setIsComplete] = useState(false);
  const [editStatus, setEditStatus] = useState<EditUserStatus>({
    status: 'idle',
    message: '',
  });
  const functions = useFunctions();
  const getlUser = useMemo(
    () => httpsCallable<any, PortalUser>(functions, 'admingetportaluser'),
    [functions]
  );

  const updatePortalUser = useMemo(
    () => httpsCallable(functions, 'updateportaluser'),
    [functions]
  );
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);

  const handleSave = useCallback(async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      setEditStatus({
        status: 'saving',
        message: 'Saving...',
      });
    }
  }, [isSubmitting]);

  const getPortalUser = useCallback(async () => {
    const user = await getlUser({ email: userId });
    setUserData(user.data);
  }, [userId, getlUser]);

  useEffect(() => {
    if (!userData) {
      getPortalUser();
    }
  }, [getPortalUser, userData]);

  useEffect(() => {
    if (isSubmitting) {
      // console.log('submit user data', userData);
      updatePortalUser({
        ...userData,
        timezone,
      })
        .then((result) => {
          // console.log('result', result);
          const data = (result?.data || '{}') as UserDataProps;
          const { success, message } = data;
          if (!success) {
            setEditStatus({
              status: 'error',
              message,
            });
            return;
          } else {
            if (navigateTo) {
              navigate(navigateTo);
            }
            setEditStatus({
              status: 'success',
              message,
            });
            getPortalUser();
          }
        })
        .catch((error) => {
          setEditStatus({
            status: 'error',
            message: error.message,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsComplete(true);
        });
    }
  }, [
    isSubmitting,
    navigate,
    navigateTo,
    updatePortalUser,
    getPortalUser,
    userData,
    timezone,
  ]);

  if (!userData) {
    return (
      <MDBox display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress />
      </MDBox>
    );
  }

  const userIsValid = () => {
    return (
      userData.name &&
      userData.name.length > 2 &&
      userData.email &&
      validator.isEmail(userData.email)
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} px={{ xs: 3, sm: 6, xl: 12 }}>
        <MDTypography variant="h3" fontWeight="bold" textTransform="capitalize">
          Edit User
        </MDTypography>
        <MDBox mt={3}>
          <Card>
            <MDBox p={{ xs: 3, sm: 6 }}>
              <MDBox>
                <MDBox>
                  <UserInfo userData={userData} setUserData={setUserData} />
                </MDBox>
              </MDBox>
              {editStatus.status === 'error' && (
                <MDBox mt={3}>
                  <MDTypography variant="h6" color="error">
                    {"Error saving user's data:"}
                  </MDTypography>
                  <MDTypography variant="body1" color="error">
                    {editStatus.message}
                  </MDTypography>
                </MDBox>
              )}
              {editStatus.status === 'success' && (
                <MDBox mt={3}>
                  <MDTypography variant="body1" color="success">
                    {editStatus.message}
                  </MDTypography>
                </MDBox>
              )}

              <MDBox mt={3}>
                <MDBox display="flex" justifyContent="flex-end" mb={3}>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    onClick={() => navigate(-1)}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </MDButton>
                  &nbsp;
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleSave}
                    disabled={isSubmitting || !userIsValid()}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default EditUser;
