// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import MDEditor from 'components/MDEditor';
import MDBadge from 'components/MDBadge';
import Grid from '@mui/material/Grid';

// NewLicence page components
import { ProviderData } from './NewProvider';

function NewProviderConfirm({
  providerData,
}: {
  providerData: ProviderData;
}): JSX.Element {
  const {
    name,
    contact_email,
    address,
    contact_phone,
    description,
    subscriptionPlan,
  } = providerData;
  console.log('providerData', providerData);
  return (
    <MDBox>
      <MDTypography variant="h5">Confirm New Provider</MDTypography>
      <MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h3" fontWeight="bold">
            Provider Details
          </MDTypography>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox mt={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  Name
                </MDTypography>
              </MDBox>
              <MDBox mb={1} ml={0.5}>
                <MDTypography variant="body2" fontWeight="regular" color="text">
                  {name}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mt={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  Email
                </MDTypography>
              </MDBox>
              <MDBox mb={1} ml={0.5}>
                <MDTypography variant="body2" fontWeight="regular" color="text">
                  {contact_email}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox mt={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  Address
                </MDTypography>
              </MDBox>
              <MDBox mb={1} ml={0.5}>
                <MDTypography variant="body2" fontWeight="regular" color="text">
                  {address}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mt={1}>
                <MDTypography variant="h6" fontWeight="medium">
                  Phone
                </MDTypography>
              </MDBox>
              <MDBox mb={1} ml={0.5}>
                <MDTypography variant="body2" fontWeight="regular" color="text">
                  {contact_phone}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <MDBox mt={1}>
            <MDTypography variant="h6" fontWeight="medium">
              Subscription Plan
            </MDTypography>
          </MDBox>
          <MDBox mb={1} ml={0.5}>
            <MDBadge
              variant="contained"
              color="success"
              badgeContent={subscriptionPlan.name}
              container
            />
          </MDBox>
        </MDBox>

        {description && (
          <>
            <MDBox mt={1}>
              <MDTypography variant="h6" fontWeight="medium">
                Description
              </MDTypography>
            </MDBox>
            <MDBox mb={3} ml={0.5}>
              <MDTypography
                variant="body2"
                color="text"
                fontWeight="regular"
                verticalAlign="middle"
              >
                {description}
              </MDTypography>
            </MDBox>
          </>
        )}
      </MDBox>
    </MDBox>
  );
}

export default NewProviderConfirm;
