import { useCallback, useState } from 'react';
import validator from 'validator';
import {
  CountryCode,
  parsePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

// react-router-dom components
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

// @mui material components
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import FormField from './FormField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

// Images
import bgImage from 'assets/images/taustakuva.jpg';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Footer from 'layouts/authentication/components/Footer';

import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { GenericResponse, SignUpRequest } from 'firebaseModels';
import { CountryType, countries } from 'consts';
import Typography from '@mui/material/Typography';
import TermsAndConditions from './TermsAndConditions';
import { Link } from 'react-router-dom';

function SignUp(): JSX.Element {
  const [providerData, setProviderData] = useState<SignUpRequest>({
    firstName: '',
    lastName: '',
    initials: '',
    title: '',
    email: '',
    organizationName: '',
    organizationRole: '',
    phoneNumber: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: null,
    username: '',
    password: '',
    invoice_vat_number: '',
    invoice_email: '',
    invoice_adress_is_same_as_main: true,
    invoice_organization_name: '',
    invoice_address: '',
    invoice_address2: '',
    invoice_city: '',
    invoice_state: '',
    invoice_zipCode: '',
    invoice_country: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const theme = useTheme();
  const [checkBox, setCheckBox] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const functions = useFunctions();
  const signup_callable = httpsCallable<SignUpRequest, GenericResponse>(
    functions,
    'signupnew'
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    firstName,
    lastName,
    email,
    organizationName,
    organizationRole,
    country,
    username,
    password,
    invoice_adress_is_same_as_main: invoiceAddressIsSameAsMain,
  } = providerData;

  let phoneNumber = providerData.phoneNumber;
  try {
    phoneNumber = parsePhoneNumber(
      providerData.phoneNumber,
      country?.code as CountryCode
    ).formatInternational();
  } catch (e) {}

  const phoneNumberIsValid =
    validatePhoneNumberLength(phoneNumber, country?.code as CountryCode) ===
      undefined &&
    isValidPhoneNumber(phoneNumber, country?.code as CountryCode);

  const invoiceAddressIsValid =
    (invoiceAddressIsSameAsMain &&
      providerData.address?.length > 2 &&
      providerData.city?.length > 2 &&
      providerData.state?.length > 1 &&
      providerData.zipCode?.length > 2 &&
      providerData.country !== null) ||
    (!invoiceAddressIsSameAsMain &&
      providerData.invoice_address?.length > 2 &&
      providerData.invoice_city?.length > 2 &&
      providerData.invoice_state?.length > 1 &&
      providerData.invoice_zipCode?.length > 2 &&
      providerData.invoice_country !== null);

  const provideDataIsValid =
    invoiceAddressIsValid &&
    firstName?.length > 2 &&
    lastName?.length > 2 &&
    email?.length > 2 &&
    validator.isEmail(providerData.email) &&
    organizationName?.length > 2 &&
    organizationRole?.length > 2 &&
    phoneNumber?.length > 2 &&
    country !== null &&
    phoneNumberIsValid &&
    username?.length > 2 &&
    validator.isEmail(providerData.username) &&
    password?.length >= 8;

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      setLoading(true);
      setError('');
      setSuccess(false);
      console.log('providerData', providerData);
      signup_callable({
        ...providerData,
        phoneNumber: parsePhoneNumber(providerData.phoneNumber).number,
      })
        .then((result) => {
          console.log('result', result);
          setLoading(false);
          if (result.data.success) {
            setSuccess(true);
          } else {
            console.log('error', result.data.message);
            setError(result.data.message);
          }
        })
        .catch((error) => {
          console.log('error', error);
          setLoading(false);
          setError(error.message);
        });
    },
    [signup_callable, providerData]
  );

  return (
    <PageLayout>
      <MDBox position="relative" minHeight="100vh" width="100%" py={0} px={0}>
        <MDBox
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            minHeight: '100vh',
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }: Theme) =>
              bgImage &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <MDBox container py={10} px={2}>
          <Grid justifyContent="center" alignItems="center" container>
            <Grid item xs={11} xl={8}>
              <Card sx={{ maxWidth: 'lg', margin: 'auto' }}>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Sign Up
                  </MDTypography>
                  <MDTypography
                    display="block"
                    variant="button"
                    color="white"
                    my={1}
                  >
                    Fill in this form to create a new customer account. 
                  </MDTypography>
                  <MDTypography
                    display="block"
                    variant="button"
                    color="white"
                    my={1}
                  >
                    Creating an account costs nothing and does not oblige you to
                    order licenses. If you have a free trial account, use the
                    same contact email to upgrade to a paid account.
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        Organization Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        type="text"
                        label="Name of Company or Organization"
                        name="organizationName"
                        value={providerData.organizationName}
                        error={'Company or organization name is required'}
                        success={providerData.organizationName?.length > 2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            organizationName: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        type="text"
                        label="Address"
                        name="address"
                        value={providerData.address}
                        error={'Address is required'}
                        success={providerData.address?.length > 2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            address: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        type="text"
                        label="Address 2"
                        name="address2"
                        value={providerData.address2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            address2: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type="text"
                        label="Zip Code"
                        name="zipCode"
                        value={providerData.zipCode}
                        error={'Zip code is required'}
                        success={providerData.zipCode?.length > 2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            zipCode: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type="text"
                        label="City"
                        name="city"
                        value={providerData.city}
                        error={'City is required'}
                        success={providerData.city?.length > 2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            city: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        type="text"
                        label="State"
                        name="state"
                        value={providerData.state}
                        error={'State is required'}
                        success={providerData.state?.length > 1}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            state: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={countries}
                        autoHighlight
                        value={providerData.country}
                        onChange={(
                          event: any,
                          newValue: CountryType | null
                        ) => {
                          setProviderData({
                            ...providerData,
                            country: newValue,
                          });
                        }}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Country"
                            name="country"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            error={'Country is required'}
                            success={providerData.country !== null}
                            sx={{
                              '& > div': {
                                '& > input': {
                                  cursor: 'pointer',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Invoicing Information
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormField
                        type="text"
                        label="Invoice Email"
                        name="invoice_email"
                        value={providerData.invoice_email}
                        error={'Email is required'}
                        success={
                          providerData.invoice_email?.length > 0 &&
                          validator.isEmail(providerData.invoice_email)
                        }
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            invoice_email: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormField
                        type="text"
                        label="VAT Number"
                        name="invoice_vat_number"
                        value={providerData.invoice_vat_number}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            invoice_vat_number: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        ml={-1}
                        mt={3}
                        mb={2}
                      >
                        <Checkbox
                          id="invoicing_checkbox"
                          checked={providerData.invoice_adress_is_same_as_main}
                          disabled={loading || success}
                          onChange={(e: any) =>
                            setProviderData({
                              ...providerData,
                              invoice_adress_is_same_as_main: e.target.checked,
                            })
                          }
                        />
                        <MDTypography
                          component="label"
                          htmlFor="invoicing_checkbox"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                        >
                          &nbsp;&nbsp;Use the main organization information for
                          invoicing
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    {!providerData.invoice_adress_is_same_as_main && (
                      <>
                        <Grid item xs={12}>
                          <FormField
                            type="text"
                            label="Name of Company or Organization for Invoicing"
                            name="invoice_organization_name"
                            value={providerData.invoice_organization_name}
                            error={'Company or organization name is required'}
                            success={
                              providerData.invoice_organization_name?.length > 2
                            }
                            onChange={(e: any) =>
                              setProviderData({
                                ...providerData,
                                invoice_organization_name: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormField
                            type="text"
                            label="Address for Invoicing"
                            name="invoice_address"
                            value={providerData.invoice_address}
                            error={'Address is required'}
                            success={providerData.invoice_address?.length > 2}
                            onChange={(e: any) =>
                              setProviderData({
                                ...providerData,
                                invoice_address: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormField
                            type="text"
                            label="Address 2 for Invoicing"
                            name="invoice_address2"
                            value={providerData.invoice_address2}
                            onChange={(e: any) =>
                              setProviderData({
                                ...providerData,
                                invoice_address2: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label="Zip Code for Invoicing"
                            name="invoice_zipCode"
                            value={providerData.invoice_zipCode}
                            error={'Zip code is required'}
                            success={providerData.invoice_zipCode?.length > 2}
                            onChange={(e: any) =>
                              setProviderData({
                                ...providerData,
                                invoice_zipCode: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label="City for Invoicing"
                            name="invoice_city"
                            value={providerData.invoice_city}
                            error={'City is required'}
                            success={providerData.invoice_city?.length > 2}
                            onChange={(e: any) =>
                              setProviderData({
                                ...providerData,
                                invoice_city: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label="State for Invoicing"
                            name="invoice_state"
                            value={providerData.invoice_state}
                            error={'State is required'}
                            success={providerData.invoice_state?.length > 1}
                            onChange={(e: any) =>
                              setProviderData({
                                ...providerData,
                                invoice_state: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            options={countries}
                            value={providerData.invoice_country}
                            onChange={(
                              event: any,
                              newValue: CountryType | null
                            ) => {
                              setProviderData({
                                ...providerData,
                                invoice_country: newValue,
                              });
                            }}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  alt=""
                                />
                                {option.label} ({option.code}) +{option.phone}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <FormField
                                {...params}
                                label="Country for Invoicing"
                                name="invoice_country"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                error={'Country is required'}
                                success={providerData.invoice_country !== null}
                                sx={{
                                  '& > div': {
                                    '& > input': {
                                      cursor: 'pointer',
                                    },
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Contact Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormField
                        type="text"
                        label="First Name"
                        name="firstName"
                        value={providerData.firstName}
                        error={'Name is required'}
                        success={providerData.firstName?.length > 2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormField
                        type="text"
                        label="Last Name"
                        name="lastName"
                        value={providerData.lastName}
                        error={'Name is required'}
                        success={providerData.lastName?.length > 2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        type="text"
                        label="Role in Organization"
                        name="organizationRole"
                        error={'Role is required'}
                        success={providerData.organizationRole?.length > 2}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            organizationRole: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormField
                        type="text"
                        label="Email"
                        name="email"
                        error={'Email is required'}
                        success={
                          providerData.email?.length > 0 &&
                          validator.isEmail(providerData.email)
                        }
                        value={providerData.email}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            email: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormField
                        type="text"
                        label="Phone Number (used for SMS verification)"
                        name="phoneNumber"
                        value={phoneNumber}
                        error={'Phone number is required'}
                        success={phoneNumberIsValid}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            phoneNumber: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Admin User Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormField
                        type="text"
                        label="Username (email)"
                        name="username"
                        value={providerData.username}
                        error={'Username is required'}
                        success={
                          providerData.username?.length > 0 &&
                          validator.isEmail(providerData.username)
                        }
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            username: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormField
                        type="password"
                        label="Password"
                        name="password"
                        value={providerData.password}
                        error={'Password is required'}
                        success={providerData.password?.length >= 8}
                        onChange={(e: any) =>
                          setProviderData({
                            ...providerData,
                            password: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        ml={-1}
                        mt={2}
                        mb={2}
                      >
                        <Checkbox
                          id="agree_checkbox"
                          checked={checkBox}
                          disabled={loading || success}
                          onChange={(e: any) => setCheckBox(e.target.checked)}
                          sx={{ color: 'info', height: '1em' }}
                        />
                        <MDTypography
                          component="label"
                          htmlFor="agree_checkbox"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                        >
                          &nbsp;&nbsp;I agree to the&nbsp;
                        </MDTypography>
                        <MDTypography
                          component="a"
                          href="#"
                          variant="button"
                          fontWeight="bold"
                          color="info"
                          textGradient
                          onClick={handleClickOpen}
                        >
                          Terms and Conditions
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        display="flex"
                        mb={2}
                        sx={{
                          '& svg': {
                            color: 'info',
                            mr: 1,
                          },
                          mt: {
                            xs: -1,
                            md: 2,
                          },
                          mk: {
                            sx: -1,
                            md: 0,
                          },
                          justifyContent: {
                            xs: 'flex-start',
                            md: 'flex-end',
                          },
                        }}
                      >
                        <MDTypography variant="button">
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            size="lg"
                            color="#F40F02"
                          />
                          <MDTypography
                            component="a"
                            href="https://doc.oneirostherapies.com/Oneiros_clinical_and_technical_overview.pdf"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            target="_blank"
                            textGradient
                            ml={1}
                            sx={{ cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon
                              icon={faExternalLinkAlt}
                              color={theme.palette.info.main}
                            />
                            Is Oneiros safe to use?
                          </MDTypography>
                          &nbsp;&nbsp;(
                          <MDTypography
                            component="a"
                            href="https://doc.oneirostherapies.com/Oneiros_tekninen_ja_kliininen_kuvaus.pdf"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            target="_blank"
                            textGradient
                            sx={{ ml: 1, mr: 1, cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon
                              icon={faExternalLinkAlt}
                              color={theme.palette.info.main}
                            />
                            In Finnish
                          </MDTypography>
                          )
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                      {error && (
                        <>
                          <MDTypography
                            variant="h6"
                            color="error"
                            sx={{ mt: 3 }}
                          >
                            Error
                          </MDTypography>
                          <MDTypography variant="body1" color="error">
                            {error}
                          </MDTypography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {success ? (
                        <>
                          <MDTypography variant="h6" color="success">
                            Account Created Successfully
                          </MDTypography>
                          <MDTypography color="success">
                            You can log in now via the{' '}
                            <Link to="/login">login</Link> page.
                          </MDTypography>
                        </>
                      ) : (
                        <MDButton
                          variant="gradient"
                          color="info"
                          loading={loading}
                          disabled={
                            (loading || !checkBox || !provideDataIsValid) &&
                            !success
                          }
                          onClick={handleSubmit}
                        >
                          Create Account
                        </MDButton>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          <Footer light />
        </MDBox>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Terms and Conditions'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TermsAndConditions />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose} color="info">
            OK
          </MDButton>
        </DialogActions>
      </Dialog>
    </PageLayout>
  );
}

export default SignUp;
