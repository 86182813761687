import { ChangeEvent, useCallback, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/taustakuva.jpg';
import MDAlert from 'components/MDAlert';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useAuth } from 'reactfire';

function ForgotPassword(): JSX.Element {
  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const resetPassword = useCallback(
    (email: string) => {
      setError(null);
      setMessage(null);
      setSuccess(false);
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setMessage('Password reset email sent!');
          setSuccess(true);
        })
        .catch((error) => {
          setError(error);
        });
    },
    [auth]
  );

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          textAlign="center"
          p={3}
        >
          <MDTypography variant="h4" fontWeight="bold" color="white">
            Forgot Password?
          </MDTypography>
          <MDTypography variant="h5" color="white">
            Enter your email to reset your password
          </MDTypography>
        </MDBox>
        <MDBox p={3}>
          {error && (
            <MDAlert severity="error" mb={3}>
              {error.message}
            </MDAlert>
          )}
          {message && (
            <MDAlert severity="success" mb={3}>
              {message}
            </MDAlert>
          )}
          <MDBox mb={3}>
            <MDInput
              fullWidth
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
            />
          </MDBox>
          {success ? (
            <MDButton
              fullWidth
              variant="contained"
              color="primary"
              href="/signin"
            >
              Return to Sign In
            </MDButton>
          ) : (
            <MDButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => resetPassword(email)}
            >
              Reset Password
            </MDButton>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
