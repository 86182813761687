/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Provider Page: https://www.creative-tim.com/Provider/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';

// NewProvider page components
import FormField from './FormField';
import { useAuth } from 'reactfire';
import { PortalUser } from 'firebaseModels';
import { usePortalUser } from 'routes';

function ProviderInfo({
  userData,
  setUserData,
}: {
  userData: PortalUser;
  setUserData: Function;
}): JSX.Element {
  const { currentUser } = useAuth();
  // console.log('currentUser', currentUser);
  const email = currentUser?.providerData[0]?.email || '';
  const { portalUser } = usePortalUser(email);

  const isSuperAdmin = portalUser?.level === 'superadmin';
  const isReseller = portalUser?.level === 'reseller';

  const user_levels = ['user', 'admin'].concat(
    isSuperAdmin ? ['superadmin', 'reseller'] : isReseller ? ['reseller'] : []
  );

  return (
    <MDBox pt={4} pb={3} px={3}>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <MDTypography variant="h6" gutterBottom>
            User Information
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <FormField
            type="text"
            label="Full Name"
            name="name"
            value={userData.name || ''}
            error={'User name is required'}
            success={userData.name?.length > 2}
            onChange={(e: any) =>
              setUserData({
                ...userData,
                name: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MDBox>
            <MDBox display="inline-block">
              <MDTypography variant="h6" gutterBottom sx={{ mt: 3 }}>
                User Level
              </MDTypography>
            </MDBox>
            {
              <Autocomplete
                disableClearable
                options={user_levels}
                renderInput={(params) => {
                  return <MDInput variant="standard" {...params} />;
                }}
                onChange={(e: any, value: string) => {
                  setUserData({
                    ...userData,
                    level: value,
                  });
                }}
                value={userData.level || ''}
                defaultValue={userData.level}
              />
            }
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Contact Information
          </MDTypography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormField
            type="text"
            label="Email"
            name="email"
            value={userData.email || ''}
            onChange={(e: any) =>
              setUserData({
                ...userData,
                email: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ProviderInfo;
